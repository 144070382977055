<template>
  <div class="sender-card">
    <div class="sender-card-order">
      <div class="sender-card-order__header">Ordrenummer</div>
      <div class="sender-card-order__number">{{ senderCard.orderNumber }}</div>
    </div>
      <div class="sender-card-sender__header">Afsenderinformation</div><br>
      <div class="sender-card-sender">
        <div class="sender-card-sender-column1">
          <div class="sender-card-sender__company-header">Virksomhed</div>
          <div class="sender-card-sender__company">{{ senderCard.company }}</div>
          <div class="sender-card-sender__packages-num-header">Antal pakker</div>
          <div class="sender-card-sender__packages-num">{{ senderCard.packagesNum }}</div>
        </div>
        <div class="sender-card-sender-column2">
          <div class="sender-card-sender__company-header">weight (kg)</div>
          <div class="sender-card-sender__company">{{ senderCard.weight }}</div>
          <div class="sender-card-sender__company-header">Volumen</div>
          <div class="sender-card-sender__company">{{ senderCard.volume }}</div>
          <div class="sender-card-sender__company-header"> Booket services</div>
          <div class="sender-card-sender__company">{{ senderCard.services }}</div>
        </div>
      </div>

    <div class="sender-card-actions">
      <!-- <div class="sender-card-actions__support">
        <btn
          label="Afsenders kundeservice"
          width="100%"
          @click="$emit('service-click')"
        />
      </div>
      <div class="sender-card-actions__options">
        <btn
          label="Skift leveringsmuligheder"
          width="100%"
          @click="$emit('options-click')"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
//import Btn from "@/components/Btn";
export default {
  name: "SenderCard",
  components: {
    //Btn,
  },
  props: {
    senderCard: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
};
</script>

<style>
.sender-card {
  margin-top: 30px;
  background-color: #fff;
  color: #1d4962;
  padding: 20px 16px;
  border-radius: 8px;
}
/* Order */
.sender-card-order {
  margin-bottom: 20px;
}
.sender-card-order__header {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: #56bae0;
}
.sender-card-order__number {
  font-size: 32px;
  font-weight: bold;
  color: #df6138;
}
/* Sender */
.sender-card-sender__header {
  color: #56bae0;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.sender-card-sender__company-header,
.sender-card-sender__packages-num-header {
  font-weight: bold;
}
.sender-card-sender__company {
  margin-bottom: 10px;
}
/* Actions */
.sender-card-actions {
  margin-top: 30px;
}
.sender-card-actions__support {
  margin-bottom: 10px;
}

@media screen and (min-width: 1024px) {

  .sender-card-sender {
    display: flex;
    flex-wrap: wrap;
  }

  .sender-card-sender-column1 {
    width: 50%;
  }

  .sender-card-sender-column2 {
    width: 50%;
  }

  .sender-card-actions {
    display: flex;
    justify-content: space-between;
  }
}
</style>
