<template>
  <div class="track">
    <row background-color="#1d4962">
      <statusbar :items="statusItems" :current="activate_icons" />
      <status-message
        v-if="statusMessage.isVisible"
        :header="statusMessage.header"
        :message="statusMessage.message"
      />
    </row>
    <row>
      <columns>
        <column>
          <greeting-message
            v-if="greetingMessage.isVisible"
            :header="greetingMessage.header"
            :message="greetingMessage.message"
          />
          <delivery-message
            v-if="deliveryMessage.isVisible"
            :header="deliveryMessage.header"
            :time="deliveryMessage.time"
            :date="deliveryMessage.date"
          />
        </column>
        <column>
          <sender-card
            :senderCard="senderCard"
            @service-click="senderServiceClick"
            @options-click="senderOptionsClick"
          />
        </column>
      </columns>
    </row>
    <row>
      <btn label="Leveringsmuligheder" width="200px" right @click="deliveryClick" />
    </row>
    <row>
      <package-list :packages="packages" />
    </row>
    <row>
      <btn
        label="Spor en anden forsendelse"
        center
        @click="trackDelivery"
      /><br /><br /><br />
    </row>
    <div>
      <Deliverymodal
          v-show="isModalVisible"
          @close="closeModal"
          @selectedOption="selectedOption"
      />

      <Additionalmodal
          v-show="isAddModalVisible"
          :deliveryText="deliveryText"
          @closeadd="closeModalAdd"
          @acceptClick="acceptClick"
      />
    </div>
  </div>
</template>

<script>
import Row from "@/components/Row";
import Columns from "@/components/Columns";
import Column from "@/components/Column";
import Statusbar from "@/components/Statusbar";
import StatusMessage from "@/components/StatusMessage";
import GreetingMessage from "@/components/GreetingMessage";
import DeliveryMessage from "@/components/DeliveryMessage";
import SenderCard from "@/components/SenderCard";
import PackageList from "@/components/PackageList";
import Btn from "@/components/Btn";
import axios from "axios";
import Deliverymodal from '@/components/DeliveryModel.vue';
import Additionalmodal from '@/components/AdditionalModal.vue';

export default {
  name: "Track",
  components: {
    Row,
    Column,
    Columns,
    Statusbar,
    StatusMessage,
    GreetingMessage,
    DeliveryMessage,
    SenderCard,
    PackageList,
    Btn,
    Deliverymodal,
    Additionalmodal
  },
  data() {
    // Dummy data
    return {
      deliveryText:'',
      isAddModalVisible:false,
      isModalVisible: false,
      activate_icons:0,
      statusItems: [
        { id: "data", label: "Data modtaget"},
        { id: "received", label: "Forsendelse modtaget" },
        { id: "planned", label: "Rute planlagt"},
        { id: "delivering", label: "På vej til dig"},
        { id: "delivered", label: "Leveret"},
      ],
      statusMessage: {
        isVisible: true,
        header: "En eller flere pakker er under levering",
        message:
          "For mere information, klik på bjælken ‘Overblik over pakker’ nedenfor.",
      },
      greetingMessage: {
        isVisible: true,
        header: "",
        message: "",
      },
      deliveryMessage: {
        isVisible: true,
        //header: "Forventet levering mellem:",
        header: "",
        time: "",
        date: "",
      },
      senderCard: {
        orderNumber: "",
        company: "",
        packagesNum: "",
        weight:"",
        volume:"",
        services: ""
      },
      packages: [
      // {
      //   packageNumber: "",
      //   // description: "Pakkens indhold beskrives her",
      //   weight: "",
      //   volume: "",
      //   status: "Din pakke forventes at blive leveret i løbet af dagen",
      //   statuses: [
      //     {
      //       description: "Din pakke er ankommet til et BFT depot",
      //       date: "26.03.2021 14:10",
      //       location: "Danmark Køge",
      //     },
      //     {
      //       description: "Din pakke er ankommet til BFT depot",
      //       date: "26.03.2021 14:10",
      //       location: "Danmark Køge",
      //     },
      //     {
      //       description: "Din pakke er ankommet til BFT",
      //       date: "26.03.2021 14:10",
      //       location: "Danmark Køge",
      //     },
      //     {
      //       description:
      //         "Afsender har opretttet label til di pakke, men den er ikke kommet til os endnu. Evt. er chaufføren ikke ankommet til depotet eller afsender har ikke givet os pakken endnu. Prøv igen efter kl. 20.00. Er der stadig ingen scanninger, så kontakt afsender.",
      //       date: "26.03.2021 14:10",
      //       location: "Danmark Køge",
      //     },
      //   ],
      // }
      ],
      headerdata : [],
      exactDeliveryTime : ""
    };
  },
  created() {
    if(this.$route.query.number != null){
      this.gettracktracedata();
    }else{
      console.log('waiting for tracenumber');
    }
  },
  methods: {
    senderServiceClick() {
      console.log("Senders customer service");
    },

    senderOptionsClick() {
      console.log("Change delivery options");
    },

    trackDelivery() {
      console.log("Track another delivery");
    },

    gettracktracedata(){
      var tracenumber = this.$route.query.number;
      this.senderCard.orderNumber = this.$route.query.number;
      //console.log(tracenumber);

        var config_pac = {
          method: "get",
          url: "https://engine.bftlogistik.dk:7000/partner/v2/tracktrace/" + tracenumber,
          headers: {
            "Content-Type": "application/json"
          }
        }
        axios(config_pac)
        .then((response) => {
          this.greetingMessage.header = response.data.headerName;
          var deliveryTime = response.data.exactDeliveryTime;
          this.exactDeliveryTime = new Date(deliveryTime).getHours().toString().padStart(2, '0')+':'+new Date(deliveryTime).getMinutes().toString().padStart(2, '0');
          var config_new = {
            method: "get",
            url: "https://engine.bftlogistik.dk:7000/partner/v3/tracktrace/" + tracenumber,
            headers: {
              "Content-Type": "application/json"
            }
          }
          axios(config_new)
          .then((response) => {
            //console.log(response.data.header.icons);   
            this.activate_icons = parseInt(response.data.header.icons);
            var deliverydate = response.data.header.Date;
            this.deliveryMessage.date = new Date(deliverydate).getDate()+'. '+(new Date(deliverydate).toLocaleString('default', { month: 'long' }))+' '+new Date(deliverydate).getFullYear();
            //this.deliveryMessage.time = response.data.header.DelTime;
            this.senderCard.services = response.data.header.serviceText;
            this.senderCard.weight = Math.round(response.data.header.Weight);
            this.senderCard.volume = Number(response.data.header.Volumen).toFixed(3);
            this.senderCard.packagesNum = response.data.header.Colli.toString();
            this.senderCard.company = response.data.header.company;

            this.greetingMessage.message = response.data.header.deliveryText;

            if(response.data.header.icons == '5'){
                this.deliveryMessage.header = "Forsendelse er leveret : ";
                this.deliveryMessage.time = this.exactDeliveryTime;
            }else{
              this.deliveryMessage.header = "Forventet levering mellem : ";
              this.deliveryMessage.time = response.data.header.DelTime;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        })
        .catch(function (error) {
          console.log(error);
        });

      var config = {
        method: "get",
        url: "https://engine.bftlogistik.dk:7000/partner/v2/tracktrace/"+ tracenumber,
        headers: {
          "Content-Type": "application/json"
        }
      };
      axios(config)
      .then((response) => {  

        response.data.packages.forEach((element) => {
          let temp = [];
          var config = {
            method: "get",
            url: "https://engine.bftlogistik.dk:7000/partner/v3/packagescans/" + element.pakke_ID,
            headers: {
              "Content-Type": "application/json"
            }
          };
          axios(config)
          .then((response) => {
            //console.log(response);
            response.data.scans &&
            response.data.scans.forEach((elem) => {
              temp.push({
                description :elem.line,
                location :elem.Status,
                date :new Date(elem.DatoTid).getDate().toString().padStart(2, '0')+'.'+(new Date(elem.DatoTid).getMonth()+1).toString().padStart(2, '0')+'.'+new Date(elem.DatoTid).getFullYear()+' '+new Date(elem.DatoTid).getHours().toString().padStart(2, '0')+':'+new Date(elem.DatoTid).getMinutes().toString().padStart(2, '0')
              });
            });
            this.packages.push({
              packageNumber : element.pakke_ID,
              weight : Math.round(element.Weight_pakke),
              volume:  Number(element.Volumen_pakke).toFixed(3),
              //status: element.textStatus,
              status : "klik her for pakkedetaljer",
              content : element.content,
              statuses : temp,
              location : Array()
            });
          })
          .catch(function (error) {
            console.log(error);
          });
        });
      })
      .catch(function (error) {
        console.log(error.response);
        if(error.response.status == 404)
        {
          if(error.response.data.status == false){
            alert(error.response.data.message);
            window.location.href = '/#/notfound';
          }
        }
      });
    },
    closeModal() {
      this.isModalVisible = false;
    },
    closeModalAdd() {
      this.isAddModalVisible = false;
    },
    deliveryClick(){
      this.isModalVisible = true;
    },
    selectedOption(event){
      this.deliveryText = event;
      console.log(this.deliveryText);
      this.isAddModalVisible = true;
    },
    acceptClick(){
      var tracenumber = this.$route.query.number;
      var deliverydata = {
        "message": this.deliveryText
      }
      var config = {
        method: "post",
        url: "https://engine.bftlogistik.dk:7000/partner/v1/deliverymessage/"+tracenumber,
        headers: {
          "Content-Type": "application/json"
        },
        data: deliverydata
      };
      axios(config)
      .then((response) => {
        console.log(response);
        if(response.data.messageStatus == "ok"){
          alert("Besked sendt til chaufføren");
          this.isModalVisible = false;
          this.isAddModalVisible = false;
        }else{
          alert("mislykkedes");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  },
};
</script>
